import { useEffect } from 'react';

const Hannover96 = () => {
  useEffect(() => {
    localStorage.setItem('club_id', 810);
    localStorage.setItem('club_name', 'Hannover 96');

    window.location.assign('/');
  });

  return null;
};
export default Hannover96;
